import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap"
import ContactImg from "../components/contactimg"
import styled from "styled-components"
import {colors, fonts} from "../../theme.js"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact | Spark Files" description="Got a question on etymology, customs or origins of expressions? Contact Spark Files." />
    <Container fluid={true}>
      <Row>
        <Col lg="6" className="dual-left p-0">
          <ImageBorder>
            <ContactImg />
          </ImageBorder>
        </Col>
        <Col lg="6" className="about-content px-sm-5 py-4">
          <div>
            <h1 className="text-center">Contact Us</h1>
            <hr />
            <p>
              Send us a quick message here and we'll get back to you as soon as
              possible:
            </p>
            <Form method="post" action="#" data-netlify="true" name="contact">
              <FormGroup>
                <Input type="hidden" name="form-name" value="contact" />
              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  required
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Email"
                  required
                />
              </FormGroup>
              <FormGroup>
                <Input
                  name="message"
                  id="message"
                  type="textarea"
                  placeholder="Message"
                  required
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="submit"
                  className="email-submit"
                  value="Send Message"
                />
              </FormGroup>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default ContactPage

const ImageBorder = styled.div`
  border-right: 4px solid ${colors.meatMedium};
  @media (max-width: 980px) {
    border-right: none;
    border-bottom: 4px solid ${colors.meatMedium};
  }
`
